<template>
  <div
    id="counterpart-selection-div-202101041508"
    class="counterparts-block mb-3"
    :style="getAdditionalStyles"
  >
    <!-- Heading -->
    <div class="counterparts-block__heading subTitle">
      <div class="d-flex align-items-center">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-2'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g opacity=".8" fill="#2B2727"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.795 8.373a4.11 4.11 0 001.569.31c2.216 0 4.019-1.746 4.019-3.892S13.58.9 11.364.9a4.11 4.11 0 00-1.569.309c1.44.592 2.45 1.975 2.45 3.582 0 1.607-1.01 2.99-2.45 3.582zM12.612 9.575a5.155 5.155 0 001.97-.972c1.519 1.004 2.518 2.694 2.518 4.605v1.965c0 1.062-.893 1.927-1.99 1.927h-2.707l-.006-.045c.894-.189 1.565-.96 1.565-1.882v-1.965c0-1.566-.378-2.716-1.35-3.633z"/><path d="M6.658 8.682c2.216 0 4.019-1.745 4.019-3.891S8.874.9 6.657.9C4.443.9 2.64 2.646 2.64 4.791c0 2.146 1.803 3.891 4.019 3.891z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6.658 9.73a5.18 5.18 0 01-3.24-1.128C1.899 9.607.9 11.297.9 13.208v1.964c0 1.063.893 1.927 1.99 1.927H10.404c1.097 0 1.99-.864 1.99-1.927v-1.964c0-1.911-.999-3.6-2.518-4.606A5.18 5.18 0 016.658 9.73z"/></g></svg>
        <span>{{ staticText.counterpartLabel }}</span>
      </div>
      <button
        v-if="canUseCRM"
        class="add-counterpart"
        @click="openCounterpartSelectionModal"
      >
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.853 6.353H8.647V1.147a1.147 1.147 0 00-2.294 0v5.206H1.147a1.147 1.147 0 000 2.294h5.206v5.206a1.147 1.147 0 002.294 0V8.647h5.206a1.147 1.147 0 000-2.294zM7.65.457a.708.708 0 00-.856.69v5.647H1.147h5.647V1.147a.706.706 0 01.856-.69z" fill="#fff"/></svg>
      </button>
    </div>

    <!-- Body -->
    <div class="counterparts-block__body">
      <progress-indicator
        v-if="isCrmDataLoading"
        class="my-3"
      ></progress-indicator>
      <div
        v-else-if="!canUseCRM"
        class="d-flex align-items-center no-counterpart"
      >
        <span class="mr-2">
          {{ staticText.listEmptyText }}
        </span>
        <router-link
          to="/users/integrations"
          target="_blank"
          class="d-flex align-items-center"
        >
          {{ staticText.connectCrmLinkText }}
          <img
            src="../../../public/img/icons/jump_external.svg"
            width="16"
            class="ml-1"
          />
        </router-link>
      </div>
      <div v-else-if="!!counterparts">
        <div
          v-if="!counterparts.length"
          class="no-counterpart"
        >
          {{ staticText.counterpartNotSelected }}
        </div>
        <div v-else>
          <div
            v-for="(counterpart, index) in counterparts"
            :key="counterpart.id"
            class="single-counterpart"
          >
            <counterpart-display-cell
              :name="getCounterpartName(counterpart)"
              :type="counterpart.type"
              :crm_service="counterpart.crm_service"
              :direct_link="counterpart.direct_link"
              class="counterpart-display"
            ></counterpart-display-cell>
            <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'ml-2 cursor-pointer'" @click="$emit('unselect-counterpart', index)" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7 .6C3.48.6.6 3.48.6 7c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4C13.4 3.48 10.52.6 7 .6zm3.2 8.704l-.896.896L7 7.896 4.696 10.2 3.8 9.304 6.104 7 3.8 4.696l.896-.896L7 6.104 9.304 3.8l.896.896L7.896 7 10.2 9.304z" fill="#AAABBA"/></svg>
          </div>
        </div>
      </div>
    </div>

    <!-- counterpart selection modal-->
    <counterpart-selection-modal
      ref="counterpartSelectionModal"
      :counterparts="counterparts"
      :hideLoadCrmDataButton="true"
      @set-counterparts="value => $emit('set-counterparts', value)"
    />
  </div>
</template>

<script>
import CounterpartDisplayCell from "./CounterpartDisplayCell.vue"
import CounterpartSelectionModal from "./CallViewCounterpartComponents/CounterpartSelectionModal.vue"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import { mapGetters } from "vuex"

export default {
  name: "CallCounterpartsBlock",
  components: {
    CounterpartSelectionModal,
    CounterpartDisplayCell,
    ProgressIndicator
  },
  data () {
    return {
      staticTextDefault: {
        counterpartLabel: "Counterpart",
        counterpartNotSelected: "No counterpart selected",
        listEmptyText: "No CRM connected.",
        connectCrmLinkText: "Connect to CRM."
      }
    }
  },
  props: {
    counterparts: {
      type: Array,
      default: () => []
    },
    callSetupLeftBlockHeight: {
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      canUseCRM: "auth/canUseCRM",
      isCrmDataLoading: "auth/isCrmDataLoading"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getAdditionalStyles () {
      // NB: ctrpart is short for Counterpart
      const approxHeadingHeight = 68
      const padding = 16
      const approxNoCtrpartHeight = 72
      const approxSingleCtrpartHeight = 85

      let minHeight
      const defaultHeight = approxHeadingHeight + padding
      minHeight = defaultHeight + approxNoCtrpartHeight

      const ctrpartLength = this.counterparts.length
      if (ctrpartLength) minHeight = defaultHeight + (approxSingleCtrpartHeight * ctrpartLength)

      // Counterpart block should be max 50% of entire available height
      const maxCtrpartHeight = this.callSetupLeftBlockHeight * 0.5
      const isComputedMinHeightAllowed = minHeight <= maxCtrpartHeight

      return {
        "min-height": isComputedMinHeightAllowed ? `${minHeight}px` : `${maxCtrpartHeight}px`
      }
    }
  },
  methods: {
    openCounterpartSelectionModal () {
      this.$refs.counterpartSelectionModal.openModal()
    },
    getCounterpartName (counterpart) {
      return counterpart.name ? counterpart.name : counterpart.crm_id
    }
  }
}
</script>

<style lang="scss" scoped>

.counterparts-block {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 20px;
  background-color: $white40;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);
  &__heading {
    background-color: $grey;
    padding: 18px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    color: $slate80;
    svg {
      g {
        opacity: 1;
      }
      path {
        fill: $slate80;
        fill-opacity: 1;
      }
    }
    button.add-counterpart {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      border: 1px solid $slate20;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 10px;
        width: 10px;
        path {
          fill: $slate80;
        }
      }
    }
  }
  &__body {
    padding: 8px 16px;
    overflow-y: auto;
    .single-counterpart {
      padding: 16px 0;
      border-bottom: 1px solid $black06;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .counterpart-display {
        max-width: calc(100% - 28px);
      }
      &:last-child {
        border: none;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .no-counterpart {
      padding: 24px 0;
      color: $slate60;
      a {
        color: $slate60 !important;
        text-decoration: underline;
      }
    }
  }
}

.counterparts-list {
  max-height: 26vh;
}

</style>
